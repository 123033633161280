@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Anybody:wght@500&family=Roboto+Condensed:wght@700&display=swap');

@layer base {
    body {
        @apply font-[Anybody];
    }

    li {
        @apply px-4;
        @apply cursor-pointer;
    }
}